import React from 'react'
import { useSelector } from 'react-redux'

function HeaderTitle() {
  const user = useSelector((state) => state.login.user)

  if (Object.keys(user).length === 0) {
    return <></>
  } else {
    return (
      <h2 className='d-flex align-items-center'>
        <span className="text-black fw-bold">Welcome {user.user.name}!</span>
        <span className='useremail'>{'<' + user.user.email + '>'}</span>
      </h2>
    )
  }
}

export default HeaderTitle
